import React from "react";
// import * as classes from "../../../SCSS/content.module.scss";
import * as styles from "./PageHeader.module.scss";

import Breadcrumbs from "../../Navigation/Breadcrumbs/Breadcrumbs";
import Badge from "../../Badge/Badge";

import { GatsbyImage } from "gatsby-plugin-image";
import { readTimeCalculator } from "../../../pages/design-blog";

export default function PageHeader(props) {
  return (
    <header className={styles.header}>
      <Breadcrumbs toLink={props.toLink} linkName={props.linkName} />
      <div className={styles.headerContent}>
        <h1
          className={[styles.heading].join(" ")}
          dangerouslySetInnerHTML={{ __html: props.heading }}
        ></h1>

        <div className={styles.badgeMetadataContainer}>
          <div className={styles.badgesContainer}>
            {props.topics.map((topic, i) => {
              return <Badge badgeLabel={topic} variant={topic} key={i + 1} />;
            })}
          </div>
          {props.type === "post" ? (
            <div className={styles.metadataContainer}>
              <p>
                {props.postBody ? readTimeCalculator(props.postBody) : null}
              </p>{" "}
              <p>{props.datePublished}</p>
            </div>
          ) : (
            <div className={styles.metadataContainer}>
              <p style={{ fontWeight: 600 }}>
                {props.projectType ? props.projectType : null}
              </p>
              <p>
                {props.startDate} to {props.endDate}
              </p>
            </div>
          )}
        </div>
      </div>
      {props.image ? (
        <GatsbyImage
          objectFit="contain"
          objectPosition="center"
          className={styles.smallHeaderImage}
          image={props.image}
          alt={props.imageTitle}
        />
      ) : null}
    </header>
  );
}
