// extracted by mini-css-extract-plugin
export var caseStudyH1 = "postCaseStudy-module--case-study-h1--9rbEh";
export var dissolveIn = "postCaseStudy-module--dissolveIn--oTwKs";
export var pageContainer = "postCaseStudy-module--page-container--rUZIK";
export var postH1 = "postCaseStudy-module--post-H1--tTi48";
export var prototypeVideo = "postCaseStudy-module--prototype-video--5snq1";
export var prototypeVideo1 = "postCaseStudy-module--prototype-video1--irRLR";
export var prototypeVideo2 = "postCaseStudy-module--prototype-video2--vve8b";
export var prototypeVideo3 = "postCaseStudy-module--prototype-video3--p3OyF";
export var videosContainer = "postCaseStudy-module--videos-container--DBtk3";
export var wrapper = "postCaseStudy-module--wrapper--MM68G";