import React, { useEffect } from "react";

import * as styles from "./postCaseStudy.module.scss";

import Layout from "../../Layout/MainPageLayout";
import PageHeader from "../PageHeaders/PageHeader";
import { blogRoute } from "../../../NavItems";

import { getImage } from "gatsby-plugin-image";

export default function PostTemplate(props) {
  const { pageContext } = props;
  const post = pageContext;
  const image = getImage(post.smallHeaderImage);

  useEffect(() => {
    const links = document.querySelectorAll("article a");
    links.forEach((link) => link.setAttribute("target", "_blank"));
  });

  return (
    <Layout
      pageTitle={post.postTitle}
      pageDescription={post.postDescription}
      selected="Design Blog"
    >
      <div className={styles.pageContainer}>
        <div className={styles.wrapper}>
          <PageHeader
            type="post"
            heading={post.postTitle}
            topics={post.topics}
            toLink={`${blogRoute}`}
            linkName="Design Blog"
            datePublished={post.datePublished}
            postBody={post.postBody}
            image={image}
            imageTitle={
              post.smallHeaderImage ? post.smallHeaderImage.title : null
            }
          />
          <article
            dangerouslySetInnerHTML={{
              __html: post.postBody,
            }}
          ></article>
        </div>
      </div>
    </Layout>
  );
}
