import React from "react";
import * as classes from "./Breadcrumbs.module.scss";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CaretLeft } from "phosphor-react";

export default function BreadCrumbs(props) {
  return (
    <nav className={classes.breadcrumbs}>
      <div className={classes.breadcrumbsList}>
        <div>
          <a className={classes.breadcrumbsLink} href={props.toLink}>
            <CaretLeft weight="bold" size="12px" />
            {props.linkName}
          </a>
        </div>
      </div>
    </nav>
  );
}
